import { joinURL } from 'ufo'
import type { ProviderGetImage } from '@nuxt/image'
import { useRuntimeConfig } from '#app'

// Akamai IMQuery reference https://techdocs.akamai.com/ivm/docs/imquery#syntax-and-examples
const akamaiIMOperationsMap: Record<string, string> = {
  // use NuxtImg props directly
  background: 'BackgroundColor', // value should be like `color=000000` or `color=d8d8d8`
  // use modifiers prop
  blur: 'Blur', // value should be integer
  // use modifiers prop
  resize: 'Resize', // value should be like `width=100,height=100` or `width=100` or `height=100`
  // use modifiers prop
  opacity: 'Opacity', // value should be between 0 and 1 with decimal
  // use modifiers prop
  grayscale: 'Grayscale', // value should be true
  // use modifiers prop
  rotate: 'Rotate', // value should be like `degrees=90` or `degrees=180`
  // use modifiers prop
  scale: 'Scale', // value should be like `width=0.5,height=0.5` or `width=0.5` or `height=0.5`
  crop: 'Crop', // value should be like `width=500,height=200`
  smartCrop: 'SmartCrop', // value should be like `width=500,height=200`
}

const operationsGenerator = (
  modifiers: Record<string, string | number | undefined>,
) => {
  const formattedModifiers: Record<string, string | number | undefined> = {
    ...modifiers,
  }
  if (modifiers.width || modifiers.height) {
    formattedModifiers.resize =
      modifiers.width || modifiers.height
        ? `${modifiers.width ? `width=${modifiers.width}` : ''}${
            modifiers.width && modifiers.height ? ',' : ''
          }${modifiers.height ? `height=${modifiers.height}` : ''}`
        : undefined
    formattedModifiers.width = undefined
    formattedModifiers.height = undefined
  }
  const operations = Object.keys(formattedModifiers)
    .map((key: string) => {
      if (!formattedModifiers[key]) {
        return ''
      }
      const operation = akamaiIMOperationsMap[key]
      if (!operation) {
        return ''
      }
      const value = formattedModifiers[key]
      return `${operation}${
        typeof value === 'boolean'
          ? ''
          : typeof value === 'number'
            ? `=${value}`
            : `,${value}`
      }`
    })
    .filter(Boolean)
    .join(';')
  return operations
}

export const getImage: ProviderGetImage = (
  src,
  { modifiers = {}, baseURL } = {},
) => {
  if (!baseURL) {
    // also support runtime config
    baseURL = useRuntimeConfig().public.siteUrl
  }
  const operations = operationsGenerator(modifiers)
  return {
    url: joinURL(baseURL, src + (operations ? '?im=' + operations : '')),
  }
}
