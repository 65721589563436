
import * as akamaiRuntime$wwzxxQyr5A from '/home/node/app/base/providers/akamai.ts'
import * as ipxRuntime$XlKGd5kMVB from '/home/node/app/node_modules/.pnpm/@nuxt+image@1.9.0_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['akamai']: { provider: akamaiRuntime$wwzxxQyr5A, defaults: {"baseURL":"https://eyewear-images-dev.zenniservices.com"} },
  ['ipx']: { provider: ipxRuntime$XlKGd5kMVB, defaults: {} }
}
        